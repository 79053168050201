import PropTypes from "prop-types"
import React, { useState, useContext, useEffect } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import AccountCircle from "@material-ui/icons/AccountCircle"
import MenuItem from "@material-ui/core/MenuItem"
import Menu from "@material-ui/core/Menu"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import {
  AccountBox,
  Home,
  OndemandVideo,
  Receipt,
  ShoppingCart,
  Build,
  Settings,
  GetApp,
  Storage,
  AttachMoney,
  ShoppingBasket,
  NoteAdd,
  List as ListIcon,
} from "@material-ui/icons"
import { useSelector, useDispatch } from "react-redux"
import { requestLogout, getAlertMessage } from "../state/store"
import {
  canAccessAdmin,
  canAccessStock,
  canAccessUsers,
  canAccessWarehouse,
  isRep
} from "../utils/checkRoles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import { Alert } from "@material-ui/lab"
import { cedarBlue } from "./layout"

const CartIcon = () => <ShoppingCart />

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    cursor: "pointer",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
    minWidth: "200px",
  },
  centerImage: {
    display: "flex",
    justifyContent: "center",
  },
}))

const Header = () => {
  const [openSideMenu, setOpenSideMenu] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const [drawOpen, setDrawOpen] = React.useState(false)

  const [hideAlertMessage, setHideAlertMessage] = useState(false)

  const { loggedIn, userProfile, alertMessage, ready } = useSelector(state => state.db)
  const dispatch = useDispatch()
  const theme = useTheme()

  const largeScreen = useMediaQuery(theme.breakpoints.up("sm"))

  useEffect(() => {
    if (window.sessionStorage.getItem("hideAlertMessage")) {
      setHideAlertMessage(true)
    }
  }, [])

  const hideAlert = () => {
    window.sessionStorage.setItem("hideAlertMessage", hideAlertMessage)
    setHideAlertMessage(true)
  }

  useEffect(() => {
    if (loggedIn && ready) {
      dispatch(getAlertMessage())
    }
  }, [loggedIn, ready])

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setDrawOpen(open)
  }

  const accessAdmin =
    loggedIn &&
    userProfile &&
    userProfile.roles &&
    canAccessAdmin(userProfile.roles)
  const accessUsers =
    loggedIn &&
    userProfile &&
    userProfile.roles &&
    canAccessUsers(userProfile.roles)
  const stockCount = loggedIn && canAccessStock(userProfile.roles)

  const accessRep = loggedIn && userProfile && userProfile.roles && isRep(userProfile.roles)

  const accessWarehouse = loggedIn && userProfile && userProfile.roles && canAccessWarehouse(userProfile.roles)

  const formatMessage = (message) => {
   // Regular expression to find links in the message
   const linkRegex = /(https?:\/\/[^\s]+)/g;
    
   // Split the message into parts using the link regex
   const parts = message.split(linkRegex);
   
   // Map each part and check if it's a link or regular text
   return parts.map((part, index) => {
     if (part.match(linkRegex)) {
       // If it's a link, wrap it in an <a> tag
       return <a key={index} href={part} target="_blank" rel="noopener noreferrer">{part}</a>;
     } else {
       // If it's regular text, just display it
       return <span key={index}>{part}</span>;
     }
   });
  }

  const FullList = () => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button key="Home" onClick={() => navigate("/")}>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        {/* <ListItem button key="Dashboard" onClick={()=>navigate('/app/dashboard/')}>
            <ListItemIcon><Announcement /></ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem> */}

        {!loggedIn && (
          <>

            <ListItem
              button
              key="About"
              onClick={() => navigate("/about/")}
            >
              <ListItemIcon>
                <Receipt />
              </ListItemIcon>
              <ListItemText primary="About Us" />
            </ListItem>

            <ListItem
              button
              key="Products"
              onClick={() => navigate("/products/")}
            >
              <ListItemIcon>
                <ShoppingCart />
              </ListItemIcon>
              <ListItemText primary="Products" />
            </ListItem>

            <ListItem
              button
              key="Contact"
              onClick={() => navigate("/contact/")}
            >
              <ListItemIcon>
                <Receipt />
              </ListItemIcon>
              <ListItemText primary="Contact Us" />
            </ListItem>
          </>
        )}
        {loggedIn && (
          <>
            <ListItem
              button
              key="Sizing"
              onClick={() => navigate("/app/sizing/")}
            >
              <ListItemIcon>
                <Build />
              </ListItemIcon>
              <ListItemText primary="Sizing Tools" />
            </ListItem>


            <ListItem
              button
              key="Cart"
              onClick={() => navigate("/app/product/categories/")}
            >
              <ListItemIcon>
                <CartIcon />
              </ListItemIcon>
              <ListItemText primary="Online Shopping" />
            </ListItem>

            <ListItem
              button
              key="Products"
              onClick={() => navigate("/app/products-order/")}
            >
              <ListItemIcon>
                <ShoppingCart />
              </ListItemIcon>
              <ListItemText primary="Shopping Cart" />
            </ListItem>

            <ListItem
              button
              key="SavedQuote"
              onClick={() => navigate("/app/quote/saved")}
            >
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Saved Quotes" />
            </ListItem>

            <ListItem
              button
              key="price-list"
              onClick={() => navigate("/app/price-list/")}
            >
              <ListItemIcon>
                <AttachMoney />
              </ListItemIcon>
              <ListItemText primary="Price List" />
            </ListItem>

            <ListItem
              button
              key="Training"
              onClick={() => navigate("/app/training/")}
            >
              <ListItemIcon>
                <OndemandVideo />
              </ListItemIcon>
              <ListItemText primary="Training" />
            </ListItem>

            <ListItem
              button
              key="Downloads"
              onClick={() => navigate("/app/downloads/")}
            >
              <ListItemIcon>
                <GetApp />
              </ListItemIcon>
              <ListItemText primary="Downloads" />
            </ListItem>

            <ListItem
              button
              key="Support"
              onClick={() => navigate("/app/support/")}
            >
              <ListItemIcon>
                <Receipt />
              </ListItemIcon>
              <ListItemText primary="Support Ticket" />
            </ListItem>

          </>
        )}
      </List>

      <Divider />
      {loggedIn && (
        <>
          <List>
            <ListItem
              button
              key="Profile"
              onClick={() => navigate("/app/profile/")}
            >
              <ListItemIcon>
                <AccountBox />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItem>
          </List>
        </>
      )}
      <Divider />
      {accessWarehouse && (<>
        <List>
          <ListItem
            button
            key="AdminWarehouse"
            onClick={() => navigate("/admin/warehouse/")}
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Warehouse" />
          </ListItem>
        </List>
        <List>
          <ListItem
            button
            key="AdminClaims"
            onClick={() => navigate("/admin/claims/")}
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Claims" />
          </ListItem>
        </List>
      </>
      )}
      <Divider />
      {accessUsers && (
        <List>
          <ListItem
            button
            key="AdminUsers"
            onClick={() => navigate("/admin/users/")}
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </List>
      )}
      <Divider />

      {accessRep && (<>
        <List>
          <ListItem
            button
            key="AdminRep"
            onClick={() => navigate("/admin/rep/")}
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Rep Details" />
          </ListItem>
        </List>
        <Divider />
      </>
      )}

      {accessAdmin && (
        <>
          <List>
            <ListItem
              button
              key="AdminTowns"
              onClick={() => navigate("/admin/towns/")}
            >
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Towns" />
            </ListItem>
            <ListItem
              button
              key="AdminFlows"
              onClick={() => navigate("/admin/flows/")}
            >
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Flows" />
            </ListItem>
            <ListItem
              button
              key="AdminAudit"
              onClick={() => navigate("/admin/audits/")}
            >
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Audits" />
            </ListItem>

            <ListItem
              button
              key="AdminProducts"
              onClick={() => navigate("/admin/products/")}
            >
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Products" />
            </ListItem>
            <ListItem
              button
              key="AdminOrders"
              onClick={() => navigate("/admin/orders/")}
            >
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Orders" />
            </ListItem>
            <ListItem
              button
              key="AdminDownloads"
              onClick={() => navigate("/admin/downloads/")}
            >
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Downloads" />
            </ListItem>

            <ListItem
              button
              key="AdminAlert"
              onClick={() => navigate("/admin/alerts/")}
            >
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Alert Banner" />
            </ListItem>


            <ListItem
              button
              key="AdminCalculations"
              onClick={() => navigate("/admin/calculations/")}
            >
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Test Calculations" />
            </ListItem>
          </List>
        </>
      )}
      {/* <div className={classes.centerImage}>
      <Img fixed={data.leftImageTrade.childImageSharp.fixed} />
      </div> */}

    </div>
  )

  const classes = useStyles()
  return (
    <header className={classes.root}>
      {!hideAlertMessage && alertMessage && alertMessage.data && alertMessage.data.message !== "" && <Alert icon={false} severity={alertMessage.data.alertType??"warning"} onClose={() => { hideAlert() }}>{formatMessage(alertMessage.data.message)}</Alert>}
      <AppBar position="static" style={{
        backgroundColor: cedarBlue,   
      }}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="main-menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor="left" open={drawOpen} onClose={toggleDrawer(false)}>
            <FullList />
          </Drawer>
          {/* <Typography variant="h6" className={classes.title}>
            Main
          </Typography> */}
          <div className={classes.title} onClick={() => navigate("/")}>
            <Typography variant="h6">RESELLER PORTAL</Typography>
          </div>

          {!loggedIn && largeScreen && (<>
            <Button color="inherit" onClick={() => navigate("/about/")}>
              About Us
            </Button>
            <Button color="inherit" onClick={() => navigate("/products/")}>
              Products
            </Button>
            <Button color="inherit" onClick={() => navigate("/contact/")}>
              Contact Us
            </Button></>
          )}

          {!loggedIn && (
            <Button color="inherit" onClick={() => navigate("/login/")}>
              Login
            </Button>
          )}


          {loggedIn && (
            <Button color="inherit" onClick={() => navigate("/app/sizing/")}>
              Sizing Tools
            </Button>
          )}

          {loggedIn && (
            <Button startIcon={<CartIcon />} color="inherit" onClick={() => navigate("/app/products-order/")}>
              Cart
            </Button>
          )}

          {loggedIn && (
            <div>
              <IconButton
                aria-label="account button"
                aria-haspopup="true"
                onClick={event => {
                  setOpenSideMenu(true)
                  setAnchorEl(event.currentTarget)
                }}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                anchorEl={anchorEl}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openSideMenu}
                onClose={() => setOpenSideMenu(false)}
              >
                <MenuItem
                  onClick={() => {
                    setOpenSideMenu(false)
                    navigate("/app/profile/")
                  }}
                >
                  Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    dispatch(requestLogout())
                    setOpenSideMenu(false)
                    navigate("/", { replace: true })
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
