/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from "./header"
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
// import "./style.scss"
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from "react-redux"
import {
  setAuditsOn,
} from "../state/store"
import { Typography } from "@material-ui/core";
export const cedarOrange = '#F7941E'
export const cedarBlue = '#012940'
export const cedarGreen = '#1F733C' //'#0f9d58'
// dark #1F7333C
export const cedarYellow = '#ffeb3b'
export const cedarLightGreen = '#22B350'

const { palette } = createTheme();

const theme = createTheme({
  palette: {
    primary: {
      main: cedarGreen,
    },
    secondary: {
      main: cedarLightGreen,
      contrastText: "#fff",
    },
    orangeThing:
    {
      main: cedarOrange,
      contrastText: "#fff",
    }
  },
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  typography: {
    fontFamily: [
      'Libre Franklin',
      'sans-serif',
    ].join(','),
  },
})

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(2),
    marginTop: 'auto',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between'
  },
}));

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          activeEnv
        }
      }
      gitCommit(latest: { eq: true }) {
    hash
  }
    }
  `)
  const dispatch = useDispatch()

  useEffect(() => {
    if (data && data.site) {
      dispatch(setAuditsOn(data.site.siteMetadata.activeEnv !== 'development'))
    }
  }, [data])

  const classes = useStyles();
  return (
    <>
      {/* <ContextProvider> */}
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <div className={classes.root}
        >
          <Header siteTitle={data.site.siteMetadata.title} />

          <main className={classes.main}>{children}</main>
          <footer className={classes.footer}>
            <div>
              <Typography>© {new Date().getFullYear()} <span>Cedar Solar</span></Typography>
              <small>{data.gitCommit.hash.substring(0, 8)}</small>
            </div>
            <Link style={{ color: cedarGreen }} to="/privacy-policy/"><Typography>Privacy Policy</Typography></Link>

          </footer>
        </div>
      </ThemeProvider>
      {/* </ContextProvider> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
